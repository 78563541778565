import React, { useEffect } from "react";

const AdInterstitial = () => {
  return (
    <ins
      className="adsbymahimeta"
      id="mMTag_Responsive_85853953"
      data-placement="interstitial"
      data-size="Responsive"
    />
  );
};

export default AdInterstitial;
