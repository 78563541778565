export const stepperQuestions = [
  {
    id: 1,
    question: "What is the largest rainforest in the world?",
    options: ["Amazon", "Congo", "Sundarbans", "Daintree"],
    answer: "Amazon",
  },
  {
    id: 2,
    question: "Which gas do plants absorb from the atmosphere?",
    options: ["Oxygen", "Carbon Dioxide", "Nitrogen", "Methane"],
    answer: "Carbon Dioxide",
  },
  {
    id: 3,
    question: "Which animal is known as the King of the Jungle?",
    options: ["Tiger", "Lion", "Elephant", "Bear"],
    answer: "Lion",
  },
  {
    id: 4,
    question: "What is the square root of 64?",
    options: ["6", "7", "8", "9"],
    answer: "8",
  },
  {
    id: 5,
    question:
      "If a triangle has angles of 90°, 60°, and 30°, what type of triangle is it?",
    options: ["Scalene", "Isosceles", "Equilateral", "Right-Angle"],
    answer: "Right-Angle",
  },
  {
    id: 6,
    question: "Solve: 12 + 4 × 3 = ?",
    options: ["24", "18", "16", "20"],
    answer: "24",
  },
  {
    id: 7,
    question: "Which company is known as 'Big Blue'?",
    options: ["Microsoft", "Apple", "IBM", "Google"],
    answer: "IBM",
  },
  {
    id: 8,
    question: "What is the primary purpose of the stock market?",
    options: [
      "Selling Goods",
      "Trading Stocks",
      "Hiring Workers",
      "Storing Money",
    ],
    answer: "Trading Stocks",
  },
  {
    id: 9,
    question: "Who is credited with founding Microsoft?",
    options: ["Steve Jobs", "Larry Page", "Bill Gates", "Elon Musk"],
    answer: "Bill Gates",
  },
  {
    id: 10,
    question: "Which country is home to the Eiffel Tower?",
    options: ["Germany", "Italy", "France", "Spain"],
    answer: "France",
  },
  {
    id: 11,
    question: "Which city is famously known as 'The Big Apple'?",
    options: ["Los Angeles", "Chicago", "New York", "Miami"],
    answer: "New York",
  },
  {
    id: 12,
    question: "Which country has the longest coastline?",
    options: ["USA", "Canada", "Russia", "Australia"],
    answer: "Canada",
  },
  {
    id: 13,
    question: "Who directed the movie 'Titanic'?",
    options: [
      "Steven Spielberg",
      "James Cameron",
      "Christopher Nolan",
      "Martin Scorsese",
    ],
    answer: "James Cameron",
  },
  {
    id: 14,
    question: "Which artist is famous for the song 'Shape of You'?",
    options: ["Ed Sheeran", "Adele", "Justin Bieber", "Taylor Swift"],
    answer: "Ed Sheeran",
  },
  {
    id: 15,
    question: "Which superhero is also known as the 'Dark Knight'?",
    options: ["Superman", "Iron Man", "Batman", "Spider-Man"],
    answer: "Batman",
  },
  {
    id: 16,
    question: "What is the synonym of 'happy'?",
    options: ["Sad", "Joyful", "Angry", "Confused"],
    answer: "Joyful",
  },
  {
    id: 17,
    question: "What is the antonym of 'brave'?",
    options: ["Strong", "Cowardly", "Fearless", "Bold"],
    answer: "Cowardly",
  },
  {
    id: 18,
    question: "Which word is a noun?",
    options: ["Run", "Quick", "Happiness", "Bright"],
    answer: "Happiness",
  },
  {
    id: 19,
    question: "What does 'CPU' stand for?",
    options: [
      "Central Processing Unit",
      "Computer Personal Unit",
      "Central Program Unit",
      "Control Processing Unit",
    ],
    answer: "Central Processing Unit",
  },
  {
    id: 20,
    question: "Who founded SpaceX?",
    options: ["Jeff Bezos", "Mark Zuckerberg", "Elon Musk", "Tim Cook"],
    answer: "Elon Musk",
  },
  {
    id: 21,
    question: "What does HTML stand for?",
    options: [
      "Hyper Text Markup Language",
      "Home Tool Markup Language",
      "Hyperlinks Text Markup Language",
      "High-level Text Markup Language",
    ],
    answer: "Hyper Text Markup Language",
  },
  {
    id: 22,
    question: "Which planet is known as the Red Planet?",
    options: ["Earth", "Mars", "Jupiter", "Venus"],
    answer: "Mars",
  },
  {
    id: 23,
    question: "What is the capital of Japan?",
    options: ["Kyoto", "Osaka", "Tokyo", "Hokkaido"],
    answer: "Tokyo",
  },
  {
    id: 24,
    question: "What is the chemical symbol for water?",
    options: ["H2", "H2O", "HO", "O2"],
    answer: "H2O",
  },
  {
    id: 25,
    question: "Who painted the Mona Lisa?",
    options: [
      "Pablo Picasso",
      "Vincent van Gogh",
      "Leonardo da Vinci",
      "Claude Monet",
    ],
    answer: "Leonardo da Vinci",
  },
];

export const facts = [
  "Octopuses have three hearts and blue blood.",
  "The Eiffel Tower can be 15 cm taller during the summer, due to the expansion of iron in the heat.",
  "The Sahara Desert is the largest hot desert in the world, covering 9.2 million square kilometers.",
  "The human brain contains around 86 billion neurons.",
  "Honey never spoils. Archaeologists have found pots of honey in ancient Egyptian tombs that are over 3,000 years old and still edible.",
  "Sharks have been around for more than 400 million years, predating dinosaurs.",
  "A group of flamingos is called a 'flamboyance.'",
  "The longest river in the world is the Nile River, stretching 6,650 kilometers.",
  "Mount Everest is the highest mountain on Earth, with its peak at 8,848 meters above sea level.",
  "Jupiter is the largest planet in our solar system, with a diameter of 142,984 kilometers.",
  "The Moon is moving away from Earth at a rate of about 3.8 centimeters per year.",
  "Bananas are berries, but strawberries aren't.",
  "The Sun accounts for 99.86% of the mass in our solar system.",
  "Cows have best friends and can become stressed when they are separated from them.",
  "Michael Phelps has won the most Olympic gold medals in history, with 23 golds.",
];
