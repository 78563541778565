import React, { useEffect } from "react";

const Ad336x280 = () => {
  return (
    <div className="ad-container">
      <ins
        className="adsbymahimeta"
        id="mMTag_Responsive_55036086"
        data-size="Responsive"
        data-desktop="336x280"
        data-tablet="336x280"
        data-mobile="336x280"
        style={{ display: "inline-block" }}
      />
    </div>
  );
};

export default Ad336x280;
