import React, { useEffect } from "react";

const Ad300x250 = () => {
  return (
    <div className="ad-container">
      <ins
        className="adsbymahimeta"
        id="mMTag_Responsive_57333489"
        data-size="Responsive"
        data-desktop="300x250"
        data-tablet="300x250"
        data-mobile="300x250"
        style={{ display: "inline-block" }}
      />
    </div>
  );
};

export default Ad300x250;
