import React, { useEffect } from "react";

const Ad728x90 = () => {
  return (
    <div className="ad-container">
      <ins
        className="adsbymahimeta"
        id="mMTag_Responsive_14601943"
        data-size="Responsive"
        data-desktop="728x90"
        data-tablet="728x90"
        data-mobile="336x280"
        style={{ display: "inline-block" }}
      />
    </div>
  );
};

export default Ad728x90;
